import React from 'react'
import { Link } from 'gatsby'

export default ({ data }) => {
  return (
    <>
      {data.map(({ node }) => {
        return (
          <div key={node.id} class="post__container">
            <Link to={node.fields.slug}>
              <h1 class="post__title">{node.frontmatter.title}</h1>
            </Link>
            <h3 class="post__date">{node.frontmatter.date}</h3>
            <p class="post__gist">{node.excerpt}</p>
            <Link to={node.fields.slug} class="post__button">
              Read <span>&#10141;</span>
            </Link>
          </div>
        )
      })}
    </>
  )
}
