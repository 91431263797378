import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout.js'
import Posts from '../components/Posts.js'

export default ({ data }) => (
  <Layout>
    <Posts data={data.allMarkdownRemark.edges} />
  </Layout>
)

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            date(formatString: "MMMM, YYYY")
          }
          excerpt(pruneLength: 270)
        }
      }
    }
  }
`
